import { Component, Input, inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from '@core/services/toastr.service';
import { Folder } from '../../models/folder';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonLoadingDirective } from '@core/directives/button-disable.directive';

@Component({
  selector: 'app-delete-folder',
  templateUrl: './delete-folder.component.html',
  styleUrls: ['./delete-folder.component.scss'],
  standalone: true,
  imports: [ButtonLoadingDirective, TranslateModule],
})
export class DeleteFolderComponent {
  public readonly activeModal = inject(NgbActiveModal);
  private readonly toastr = inject(ToastrService);
  @Input() folder: Folder;
  @Input() method: any;

  public isLoading = false;

  public deleteFolder(): void {
    this.isLoading = true;
    this.method(this.folder.id)
      .then(() => {
        this.activeModal.close();
      })
      .catch(() => {
        this.toastr.error('deleteFolderModal.unableToDelete');
      })
      .finally(() => (this.isLoading = false));
  }
}
