import { NgModule } from '@angular/core';
import { type Routes, RouterModule } from '@angular/router';
import { FilesPageComponent } from './files-page/files-page.component';
import { FilesGuard } from './files.guard';

const routes: Routes = [
  {
    path: '',
    component: FilesPageComponent,
    canActivate: [FilesGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FilesRoutingModule {}
