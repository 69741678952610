import { Component, Input } from '@angular/core';
import { UploadedFile } from '../../models/file';
import { AssetSrcDirective } from '@core/directives/asset-src.directive';

@Component({
  selector: 'file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  standalone: true,
  imports: [AssetSrcDirective],
})
export class FileIconComponent {
  @Input() file: UploadedFile;
  @Input() size: 'sm' | 'lg' = 'sm';

  public get imageUrl(): string {
    let url = '';

    if (!this.file?.extension) {
      url = 'generic';
    } else {
      switch (this.file.extension) {
        case '.7z':
        case '.zip':
          url = 'zip';
          break;

        case '.ai':
        case '.bmp':
        case '.gif':
        case '.jpeg':
        case '.jpg':
        case '.png':
        case '.psd':
        case '.svg':
        case '.tif':
        case '.tiff':
          url = 'image';
          break;

        case '.csv':
        case '.xls':
          url = 'excel';
          break;

        case '.xlsx':
          url = 'excel-2007';
          break;

        case '.doc':
          url = 'word';
          break;

        case '.docx':
          url = 'word-2007';
          break;

        case '.key':
          url = 'keynote';
          break;

        case '.mdb':
          url = 'access';
          break;

        case '.pdf':
          url = 'pdf';
          break;

        case '.pps':
        case '.ppt':
          url = 'pp';
          break;

        case '.pptx':
          url = 'pp-2007';
          break;

        default:
          url = 'generic';
          break;
      }
    }

    if (this.size === 'lg') {
      url += '-large';
    }

    return `assets/svg/file-icons/${url}.svg`;
  }
}
